// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
         production: false,
         restTarget: "https://my-feedback.contractlogix.com",
         adalConfig: {
           tenant: "a5c460b6-6f06-464c-a3ce-003d212b0155",
           clientId: "29a86711-b108-4489-813b-ea8aa2c00906", // ContractLogix.CMP:ClientId web application in Azure
           endpoints: {
             "https://my-feedback.contractlogix.com/":
               "29a86711-b108-4489-813b-ea8aa2c00906" // ContractLogix.CMP:ClientId web application in Azure
           }
         },
         apiUrl: "https://my-feedback.contractlogix.com",
         appInsightsConfig: {
           host: "api.applicationinsights.io",
           appId: "a24839f0-dbc6-417a-b5ca-3beda17f797c",
           apiKey: "qdjs1nxsmgbbbzrb4jjjt2cshuqicltd2u8ee6dp"
         }
       };
